import "intl";
import "intl/locale-data/jsonp/en";
import moment from "moment";
import { Zoom, toast } from "react-toastify";

const Home = "https://api.karegiver.com/api/";
const IMG_URL = "https://api.karegiver.com/";

// const Home = "https://api.staging.karegiver.com/api/";
// const IMG_URL = "https://api.staging.karegiver.com/";

// const Home = "http://127.0.0.1:8000/api/";
// const IMG_URL = "http://127.0.0.1:8000/";

// const Home = "http://192.168.0.129:8000/api/";
// const IMG_URL = "http://192.168.0.129:8000/";

const InsuranceUrl = "https://3rdparty.universalinsuranceonline.com/API/API/";

const ErrorHandler = (err) => {
  //console.clear();
  console.log(err);
  // this.setState({ loading: false })
  if (err.response) {
    const { data } = err.response;
    toast.error(data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      transition: Zoom,
    });
  } else {
    toast.error(err.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      transition: Zoom,
    });
  }
};

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

const formatAmount = (amount, maxDigits = 8) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumSignificantDigits: maxDigits,
  }).format(amount) + ".00";

const SortByDate = (array = []) => {
  if (array.length < 1) {
    return [];
  }
  // ascending
  array.sort((a, b) =>
    moment(a.start_date, "MMM Do, h:mm:ss A").diff(
      moment(b.start_date, "MMM Do, h:mm:ss A")
    )
  );
};
export {
  Home,
  InsuranceUrl,
  formatAmount,
  ErrorHandler,
  toBase64,
  IMG_URL,
  SortByDate,
};
